import { createContext } from "react";
import swal from "sweetalert";
import moment from "moment";

//  export const apiUrl = "http://192.168.2.46:8081"
//  export const baseUrl = "http://192.168.2.46:8081/"

export const apiUrl = "https://staging-api.semilla.app"
export const baseUrl = "https://staging-api.semilla.app/"
export const imageUrl = "https://staging-api.semilla.app/"


//  export const apiUrl = "https://api.semilla.app"
//  export const baseUrl = "https://api.semilla.app/"
//  export const imageUrl = "https://semilla-prod.s3.us-west-1.amazonaws.com/"

export const UtctolocalDate=(dateTime)=>{
    return moment.utc(dateTime).local()
 }
export const defaultLanguage = "en"
export const MyContext = createContext();

export const Unauthorized=()=>{swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
    localStorage.clear()
    // window.location.href = "/";
    console.log('OK button clicked after error alert');
})}
export const login_again=()=>{swal({ icon: 'error', text: "Unauthorized", button: "OK" }).then(() => {
  localStorage.clear()
  // window.location.href = "/";
  console.log('OK button clicked after error alert');
})}


export const  dateFormate = 'DD/MM/YYYY hh:mm A'
export const formatBalance = (rawBalance) => {
    const balance = (rawBalance / 1000000000000000000).toFixed(3)
    return balance
  }
  export const transactionurl =  "https://mumbai.polygonscan.com/tx/"
  export const formatToColombianCurrency = (number) => {
    return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(number);
  };

  export const allowedImageTypes = ['image/jpeg', 'image/png','image/jpg'];
  export let purposeTranslation =[ 
    {
      label:"Unit" ,value:"UNIT"},{label:"Category " ,value:"CATEGORY"},{label:"Product Name" ,value:"PRODUCT_NAME"},{label:"Product Description" ,value:"PRODUCT_DESCRIPTION"}]
  export let FilterStatus =[
    {
      label:"pending" ,
      value: "ORDER_INITIATED"
    },
    {
        label:"rejected" ,
        value: "ORDER_REJECTED"
      },
      {
        label:"cancelled" ,
        value: "ORDER_CANCELLED"
      },
      {
        label:"out_for-delivery" ,
        value: "OUT_FOR_DELIVERY"
      },
      {
        label:"driver_not_assigned" ,
        value: "PICKUP_CONFIRMATION_PENDING"
      },
      {
        label:"delivered" ,
        value: "ORDER_DELIVERED"
      },
   
      // {
      //   label:"Driver Assigned" ,
      //   value: "PICKUP_CONFIRM"
      // }
      
]
//   export let FilterStatus =[
//     {
//       label:"pending" ,
//       value: "ORDER_INITIATED"
//     },
//     {
//         label:"Rejected" ,
//         value: "ORDER_REJECTED"
//       },
//       {
//         label:"Cancelled" ,
//         value: "ORDER_CANCELLED"
//       },
//       {
//         label:"Out For Delivery" ,
//         value: "OUT_FOR_DELIVERY"
//       },
//       {
//         label:"Driver Not Assigned" ,
//         value: "SEARCHING_DELIVERY_PARTNER"
//       },
//       {
//         label:"Delivered" ,
//         value: "ORDER_DELIVERED"
//       },
   
//       // {
//       //   label:"Driver Assigned" ,
//       //   value: "PICKUP_CONFIRM"
//       // }
      
// ]
export const getStatus = (Orderstatus)=>{

  let status = ""
  if(Orderstatus=="ORDER_INITIATED"){
    status = "pending"
}else if(Orderstatus=="ORDER_REJECTED"){
    status = "rejected"

}else if(Orderstatus=="PICKUP_CONFIRMATION_PENDING"){
    status = "driver_not_assigned"

} else if(Orderstatus=="SEARCHING_DELIVERY_PARTNER"){
    status = "driver_not_assigned"

}else if(Orderstatus=="PICKUP_CONFIRM"){
    status = "driver_assigned"

}else if(Orderstatus=="OUT_FOR_DELIVERY"){
    status = "out_for-delivery"

}else if(Orderstatus=="ORDER_CANCELLED"){
    status = "order_cancelled"

}else if(Orderstatus=="ORDER_DELIVERED"){
    status = "order_delivered"

}else if(Orderstatus=="PICKUP_REJECTED"){
    status = "pickup"
}else if(Orderstatus=="PICKUP_PARTNER_VERIFIED"){
    status = "out_for-delivery"
}else if(Orderstatus=="AT_DELIVERY_LOCATION"){
    status = "driver_arr"
}else if(Orderstatus=="ORDER_TIMED_OUT"){
  status = "order_cancelled"
}
else if(Orderstatus=="ORDER_COMPLETED"){
  status = "order_completed"
}else{
    status = Orderstatus
}

return status
}